import React, { useEffect, useState } from "react";
import { Box, Card, Container, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import Carousel from "react-multi-carousel";

import { feedback as feedbackList } from "assets/data/feedbackList";

import "react-multi-carousel/lib/styles.css";
import { getData } from "utils";

const StyledIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== "primary",
})(
  ({
    theme: {
      palette: {
        background: { light },
        primary: { main },
        secondary,
      },
    },
    primary,
  }) => ({
    color: light,
    background: primary ? main : secondary.main,
    padding: 12,
    borderRadius: 9,
    width: 54,
    textAlign: "center",
    height: 54,
  })
);

const responsiveSlider = {
  desktop: {
    breakpoint: { max: 3000, min: 1000 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1000, min: 576 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
};

const Feedback = () => {
  const [feedback, setFeedback] = useState([]);

  useEffect(() => {
    (async function fetchFeedback() {
      setFeedback(await getData("getAllFeedbacks"));
    })();
  }, []);

  return (
    <section id="feedback">
      <Container
        sx={({
          palette: {
            background: { light, dark },
          },
        }) => ({
          background: light,
          color: dark,
          borderRadius: { xs: "50px", lg: "80px" },
          p: { xs: "18px 27px", lg: "80px 100px" },
        })}
        maxWidth="xl"
      >
        <Typography
          textTransform="uppercase"
          fontWeight={700}
          textAlign="center"
          fontSize={{ xs: "20px", sm: "28px", lg: "34px" }}
        >
          Client Voices: Raving Reviews for TECHRIZZ
        </Typography>
        <Typography textAlign="center">Real Stories, Real Successe</Typography>

        {feedback?.length ? (
          <div className="slider">
            <Carousel
              responsive={responsiveSlider}
              showDots
              infinite
              arrows={false}
              renderDotsOutside
            >
              {feedback.map(
                ({ _id, first_name, last_name, message, type }, index) => (
                  <Box
                    key={_id}
                    sx={({
                      palette: {
                        gradient: { main3 },
                      },
                    }) => ({
                      padding: {
                        xs: 2,
                        sm: 4,
                      },
                      margin: { xs: 0, sm: 2 },
                      background: main3,
                      borderRadius: 8,
                      marginTop: { xs: 2, sm: index % 2 !== 0 ? 8 : 2 },
                    })}
                  >
                    <Stack direction="row" justifyContent="space-between">
                      <StyledIcon primary={index % 2 === 0}>
                        {feedbackList[index % 4].icon}
                      </StyledIcon>
                      <Typography variant="h6" fontWeight={700} padding={2}>
                        {type}
                      </Typography>
                    </Stack>
                    <Typography variant="h6" fontWeight={700}>
                      {`${first_name} ${last_name}`}
                    </Typography>
                    <Typography
                      color={({
                        palette: {
                          background: { textDark },
                        },
                      }) => textDark}
                    >
                      {message}
                    </Typography>
                  </Box>
                )
              )}
            </Carousel>
          </div>
        ) : (
          <Card
            sx={{
              paddingBlock: { xs: 8, md: 18 },
              textAlign: "center",
              margin: 2,
              outlineWidth: "12px",
            }}
            elevation={4}
          >
            Feedback not available right now
          </Card>
        )}
      </Container>
    </section>
  );
};

export default Feedback;
