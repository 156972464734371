import { Icon } from "@mui/material";
import styled from "@emotion/styled";

export const StyledIcon = styled(Icon)(
  ({
    theme: {
      palette: {
        background: { transparent },
      },
    },
    theme,
  }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 33,
    },
    marginBlock: "6px",
    borderRadius: "50%",
    marginInline: "auto",
    background: transparent,
  })
);
