import React from "react";

import homeImage from "assets/images/homeImage.png";
//icons images
import eCommerceIcon from "assets/icons/casualClothingIcon.svg";
import bookIcon from "assets/icons/openBookIcon.svg";
import briefcaseIcon from "assets/icons/briefcaseIcon.svg";
import editPencilIcon from "assets/icons/editPencilIcon.svg";
import noteIcon from "assets/icons/noteIcon.svg";
import chairIcon from "assets/icons/chairIcon.svg";
import { StyledButton } from "assets/styledComponents/StyledButton";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { StyledIcon } from "assets/styledComponents/StyledIcon";
import styled from "@emotion/styled";

const StyledTypography = styled(Typography)(({ theme }) => ({
  background: theme.palette.background.transparent,
  paddingInline: 5,
  paddingBlock: 1.5,
  borderRadius: 5,
  [theme.breakpoints.down("sm")]: {
    fontSize: "5px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "7px",
  },
}));

const StyledStack = styled(Stack)(({ top, left, right, bottom }) => ({
  position: "absolute",
  top,
  left,
  right,
  bottom,
  textAlign: "center",
  height: "37px",
  display: "flex",
  animation:
    left || right
      ? "bounce 5s ease-in-out infinite"
      : "bounceReverse 5s ease-in-out infinite",
  transition: "all 0.5s ease-in",
}));

const Main = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <section id="home">
      <Stack
        justifyContent="center"
        alignItems="center"
        direction="column"
        sx={{
          px: { xs: 0, sm: 1, lg: "100px" },
          paddingTop: { xs: "84px", md: "111px" },
        }}
      >
        <Box sx={{ position: "relative", width: "100%" }}>
          <Box textAlign="center">
            <Typography
              fontFamily="Sofia"
              color="primary"
              sx={{ fontSize: { xs: "12px", sm: "18px", lg: "24px" } }}
            >
              Build Better and Faster
            </Typography>
            <Typography
              variant="h3"
              sx={{
                textTransform: "uppercase",
                fontWeight: 600,
                fontSize: {
                  xs: "20px",
                  md: "34px",
                  lg: "45px",
                },
                margin: "10px",
              }}
            >
              🚀 Create New Avenues of
              <br /> Connection{" "}
              <Typography
                variant="span"
                sx={({
                  palette: {
                    gradient: { main2 },
                  },
                }) => ({
                  background: main2,
                  color: "transparent",
                  backgroundClip: "text",
                })}
              >
                with Quality
              </Typography>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: { xs: "12px", sm: "16px", lg: "20px" },
                fontWeight: 500,
              }}
            >
              Reach customers through innovative websites, apps,
              <br /> and digital marketing.
            </Typography>
            <StyledButton
              sx={{
                my: { xs: 2.5, lg: 5 },
              }}
              style={matches ? {} : { padding: "12px 25px" }}
              variant="a"
              href="#contactUs"
            >
              Let’s Discuss Your Idea
            </StyledButton>
          </Box>
          <StyledStack top={matches ? 0 : -34} left={matches ? "100px" : "0px"}>
            <StyledIcon>
              <img src={eCommerceIcon} alt="eCommerceProducts" />
            </StyledIcon>
            <StyledTypography variant="caption">
              eCommerce Products
            </StyledTypography>
          </StyledStack>
          <StyledStack
            top={matches ? 0 : -34}
            right={matches ? "100px" : "0px"}
          >
            <StyledIcon>
              <img src={bookIcon} alt="productivityProducts" />
            </StyledIcon>
            <StyledTypography variant="caption">
              Productivity Products
            </StyledTypography>
          </StyledStack>
          <StyledStack
            top="40%"
            left={0}
            style={matches ? {} : { display: "none" }}
          >
            <StyledIcon>
              <img src={briefcaseIcon} alt="businessStartups" />
            </StyledIcon>
            <StyledTypography variant="caption">
              Business Startups
            </StyledTypography>
          </StyledStack>
          <StyledStack
            top="40%"
            right={0}
            style={matches ? {} : { display: "none" }}
          >
            <StyledIcon>
              <img src={editPencilIcon} alt="businessIdea" />
            </StyledIcon>
            <StyledTypography variant="caption">Business Idea</StyledTypography>
          </StyledStack>
          <StyledStack
            bottom={matches ? "60px" : "70px"}
            left={matches ? "300px" : "0px"}
          >
            <StyledIcon>
              <img src={noteIcon} alt="managementProduct" />
            </StyledIcon>
            <StyledTypography variant="caption">
              Management Product
            </StyledTypography>
          </StyledStack>
          <StyledStack
            bottom={matches ? "60px" : "70px"}
            right={matches ? "300px" : "0px"}
          >
            <StyledIcon>
              <img src={chairIcon} alt="bookingProducts" />
            </StyledIcon>
            <StyledTypography variant="caption">
              Booking Products
            </StyledTypography>
          </StyledStack>
        </Box>
      </Stack>
      <Box textAlign="center" sx={{ paddingInline: 2 }}>
        <img src={homeImage} alt="home" className="banner" width={984} />
      </Box>
    </section>
  );
};

export default Main;
