import React, { useState } from "react";

import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import darkLogo from "assets/images/dark-logo.png";
import darkLogoText from "assets/images/TechRizzDark.png";
import logoText from "assets/images/TechRizz.png";
import logo from "assets/images/light-logo.png";

const Navbar = () => {
  const [navList, setNavList] = useState([
    { title: "Home", link: "#home", active: true },
    { title: "How we works", link: "#howWeWorks", active: false },
    { title: "What We Build", link: "#whatWeBuild", active: false },
    { title: "Feedback", link: "#feedback", active: false },
    { title: "About", link: "#about", active: false },
    { title: "FAQs", link: "#FAQs", active: false },
    { title: "Services", link: "#services", active: false },
  ]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const isBigScreen = useMediaQuery("(min-width:1025px)");

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const toggle = () => {
    const scrolled = document.documentElement.scrollTop;
    scrolled > 1 ? setIsScrolled(true) : setIsScrolled(false);
    const sections = document.querySelectorAll("section");
    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      if (scrolled >= sectionTop - 64) {
        setNavList((prev) =>
          prev.map((item) =>
            item.link.slice(1) === section.getAttribute("id")
              ? { ...item, active: true }
              : { ...item, active: false }
          )
        );
      }
    });
  };

  window.addEventListener("scroll", toggle);
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={({
        palette: {
          background: { light, main, dark },
        },
      }) => ({
        color: isScrolled ? dark : light,
        background: isScrolled ? light : main,
      })}
    >
      <List>
        <ListItem>
          <ListItemButton component="a" href="/">
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                flexGrow: 1,
                justifyContent: { xs: "center", md: "start" },
                paddingRight: { xs: "56px", md: "0px" },
              }}
            >
              <img
                src={isScrolled ? darkLogo : logo}
                alt="logo"
                width="30px"
                height="27px"
              />
              <img
                src={isScrolled ? darkLogoText : logoText}
                alt="logo-text"
                width="72px"
                height="24px"
              />
            </Stack>
          </ListItemButton>
        </ListItem>
        {navList.map(({ title, active, link }) => (
          <ListItem
            key={title}
            disablePadding
            onClick={() =>
              setNavList((prev) =>
                prev.map((item) =>
                  item.title === title
                    ? { ...item, active: true }
                    : { ...item, active: false }
                )
              )
            }
          >
            <ListItemButton component="a" href={link}>
              <ListItemText primary={title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <header>
      <AppBar
        component="nav"
        sx={({
          palette: {
            background: { light, main },
          },
        }) => ({
          background: isScrolled ? light : main,
          paddingInline: { lg: "100px" },
          boxShadow: "none",
        })}
      >
        <Toolbar sx={{ paddingInline: { lg: "0px" } }}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={({
              palette: {
                background: { dark, light },
              },
            }) => ({
              mr: 2,
              display: { md: "none" },
              color: isScrolled ? dark : light,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              flexGrow: 1,
              justifyContent: { xs: "center", md: "start" },
              paddingRight: { xs: "56px", md: "0px" },
            }}
          >
            <img
              src={isScrolled ? darkLogo : logo}
              alt="logo"
              width={isBigScreen ? "50px" : "30px"}
              height={isBigScreen ? "45.18px" : "27px"}
            />
            <img
              src={isScrolled ? darkLogoText : logoText}
              alt="logo-text"
              width={isBigScreen ? "120px" : "72px"}
              height={isBigScreen ? "34px" : "24px"}
            />
          </Stack>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {navList.map(({ title, active, link }, index) => (
              <Link
                href={link}
                key={index}
                sx={({
                  palette: {
                    primary: { main, transparent },
                    background: { dark, light },
                  },
                }) => ({
                  color: active ? main : isScrolled ? dark : light,
                  borderRadius: "40px",
                  fontWeight: active ? 700 : 400,
                  background: active ? transparent : "",
                  padding: "5px 14px",
                  marginInline: { md: 1, lg: "16px 18px" },
                  "&:hover": {
                    background: transparent,
                  },
                  fontSize: "16px",
                })}
                variant="plain"
                underline="none"
                onClick={() =>
                  setNavList((prev) =>
                    prev.map((item) =>
                      item.title === title
                        ? { ...item, active: true }
                        : { ...item, active: false }
                    )
                  )
                }
              >
                {title}
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={({
            palette: {
              background: { light, main, dark },
            },
          }) => ({
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: 240,
              height: "100vh",
            },
            background: isScrolled
              ? `${light} !important`
              : `${main} !important`,
          })}
        >
          {drawer}
        </Drawer>
      </nav>
    </header>
  );
};

export default Navbar;
