export const workList = [
  {
    header: "Discuss Your Idea",
    description:
      "Let's shape your ideas into a strategic plan for digital success. Collaborate with TECHRIZZ - where your vision aligns with our goals. 🚀",
    videoLink:
      "https://player.vimeo.com/external/371618575.sd.mp4?s=686dd3e858d207a06bf12e7f36b73c748f1d3600&profile_id=164&oauth2_token_id=57447761",
  },
  {
    header: "Product Development and Progress Analysis",
    description:
      "Enter development with TECHRIZZ: Experts shape your project. Stay updated for a transparent and collaborative journey, bringing your vision to life. 🛠️✨",
    videoLink:
      "https://player.vimeo.com/external/553671920.sd.mp4?s=db63fd0b96b163241e24adbf8dec968b44b2768c&profile_id=164&oauth2_token_id=57447761",
  },
  {
    header: "Testing and Product Deployment",
    description:
      "Quality is paramount. We rigorously test, refine, and seamlessly deploy your digital solution, marking the successful end of our collaboration. Your success is our priority. 🌐🚀",
    videoLink:
      "https://player.vimeo.com/external/342867313.sd.mp4?s=1e196b352b464cd53b2c10f1c8f5de1a7a3d4d37&profile_id=164&oauth2_token_id=57447761",
  },
];
