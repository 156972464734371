import React from "react";

import { Box } from "@mui/material";

import FAQs from "components/FAQs";
import Feedback from "components/Feedback";
import Footer from "components/Footer";
import HowWeWork from "components/HowWeWork";
import Main from "components/Main";
import Navbar from "components/Navbar";
import Services from "components/Services";
import WhatBuild from "components/WhatBuild";
import About from "components/About";
import ContactUs from "./ContactUs";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Box component="main">
        <Main />
        <HowWeWork />
        <WhatBuild />
        <Feedback />
        <About />
        <FAQs />
        <Services />
        <ContactUs />
      </Box>
      <Footer />
    </div>
  );
};

export default Home;
