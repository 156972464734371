import { Button } from "@mui/material";
import styled from "@emotion/styled";

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.background.light,
  background: theme.palette.gradient.main,
  [theme.breakpoints.down("sm")]: {
    padding: "10px 22px",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    margin: 20,
  },
  [theme.breakpoints.up("sm")]: {
    padding: "15px 32px",
  },
  borderRadius: "44px",
  textTransform: "none",
  fontWeight: "bold",
}));
