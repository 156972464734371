const API_END_POINT = "https://techrizz-web-backend.onrender.com/admin/";

export const getData = async (pathname, query) => {
  let finalURL = API_END_POINT + pathname;
  if (query) {
    finalURL += `?${Object.entries(query)
      .map(([key, value]) => `${key}=${value}`)
      .join("&")}`;
  }
  let res = await fetch(finalURL);
  res = await res.json();
  return res.data;
};

export const postData = async (pathname, payload) => {
  let res = await fetch(API_END_POINT + pathname, {
    body: JSON.stringify(payload),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
  res = await res.json();
  console.log(res);
  return res.message;
};

export const joinedString = (separator, ...rest) => rest.join(separator);
