import React, { useState } from "react";
import {
  Box,
  Divider,
  Stack,
  Typography,
  Grid,
  TextField,
  Backdrop,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import logo from "assets/images/light-logo.png";
import logoText from "assets/images/TechRizz.png";
import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linkedIn.svg";
import { StyledButton } from "assets/styledComponents/StyledButton";
import { postData } from "utils";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  message: "",
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter first name"),
  lastName: Yup.string().required("Please enter last name"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter email"),
  phoneNumber: Yup.string().length(10, "enter valid number"),
  message: Yup.string().required("Please enter message"),
});

const ContactUs = () => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const { firstName, lastName, message, email, phoneNumber } = values;
      setLoading(true);
      try {
        await postData("uploadMail", {
          first_name: firstName,
          last_name: lastName,
          email,
          contact: phoneNumber,
          content: message,
        });
        resetForm({ values: initialValues });
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
  });

  const {
    handleChange,
    errors,
    handleSubmit,
    values: { firstName, lastName, message, email, phoneNumber },
  } = formik;

  return (
    <Box
      id="contactUs"
      sx={({
        palette: {
          gradient: { main4 },
        },
      }) => ({
        background: main4,
        borderRadius: { xs: "20px", sm: "80px" },
        marginBottom: { xs: "10px", sm: "98px" },
      })}
    >
      <Backdrop open={loading} />
      <Stack
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        sx={{
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box
          paddingInline={{ xs: "10px", sm: "88px" }}
          sx={{
            background: "#fff",
            paddingBlock: { xs: "60px", sm: "223px" },
            borderRadius: { xs: "20px", sm: "80px" },
          }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Divider
              sx={({
                palette: {
                  primary: { main },
                },
              }) => ({
                background: main,
                height: "2px",
                width: "60px",
              })}
            />
            <Typography
              fontSize={{ xs: "20px", md: "30px" }}
              fontWeight={700}
              sx={({
                palette: {
                  gradient: { main2 },
                },
              }) => ({
                background: main2,
                color: "transparent",
                borderRadius: "5px",
                backgroundClip: "text",
              })}
            >
              GET IN TOUCH
            </Typography>
          </Stack>
          <Typography fontSize={{ xs: "20px", md: "32px" }} color="#000">
            Let’s Connect. <br /> We’re Here To Help!
          </Typography>
          <Grid container spacing={2} marginTop={3}>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined"
                fullWidth
                label="First Name*"
                value={firstName}
                variant="filled"
                name="firstName"
                onChange={handleChange}
                color="info"
                sx={{ color: "#000", background: "#fff", borderRadius: "5px" }}
              />
              <Typography variant="body2" color="error">
                {errors.firstName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="outlined"
                label="Last Name*"
                variant="filled"
                value={lastName}
                name="lastName"
                onChange={handleChange}
                color="info"
                sx={{ color: "#000", background: "#fff", borderRadius: "5px" }}
              />
              <Typography variant="body2" color="error">
                {errors.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="outlined"
                label="Email Address*"
                variant="filled"
                value={email}
                name="email"
                onChange={handleChange}
                color="info"
                sx={{ color: "#000", background: "#fff", borderRadius: "5px" }}
              />
              <Typography variant="body2" color="error">
                {errors.email}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="outlined"
                label="Phone Number"
                variant="filled"
                value={phoneNumber}
                name="phoneNumber"
                onChange={handleChange}
                color="info"
                sx={{ color: "#000", background: "#fff", borderRadius: "5px" }}
              />
              <Typography variant="caption" color="error">
                {errors.phoneNumber}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined"
                label="Message*"
                variant="filled"
                name="message"
                value={message}
                onChange={handleChange}
                color="info"
                sx={{ color: "#000", background: "#fff", borderRadius: "5px" }}
                rows={4}
                multiline
              />
              <Typography variant="body2" color="error">
                {errors.message}
              </Typography>
            </Grid>
          </Grid>
          <StyledButton sx={{ marginTop: "20px" }} onClick={handleSubmit}>
            Submit
          </StyledButton>
        </Box>
        <Box
          sx={{
            textAlign: "center",
            maxWidth: "398px",
            width: "100%",
            paddingInline: "61px",
            paddingTop: "69px",
            display: { xs: "none", sm: "block" },
            color: "#fff",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap="23px"
          >
            <img src={logo} alt="logo" width="80px" />
            <img src={logoText} alt="logoText" height="42px" />
          </Stack>
          <Typography fontWeight={700} marginBlock={2} fontSize={24}>
            Contact Information :
          </Typography>
          <Typography>
            TECHRIZZ International Ltd. 123 Tech Street, Cityville, Country
            Email: info@techrizz.com Phone: +1 (123) 456-7890
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={3}
            marginTop={4}
          >
            <FacebookIcon />
            <InstagramIcon />
            <LinkedInIcon />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default ContactUs;
