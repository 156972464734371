import React, { useState } from "react";

import {
  Container,
  Grid,
  Icon,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import styled from "@emotion/styled";

import ArticleIcon from "@mui/icons-material/Article";
import EditNoteIcon from "@mui/icons-material/EditNote";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

import describeImage from "assets/images/howWeWorkImage1.png";
import generateImage from "assets/images/howWeWorkImage2.png";
import publishImage from "assets/images/howWeWorkImage3.png";
import { workList } from "assets/data/workList";

const StyledIcon = styled(Icon, {
  shouldForwardProp: (prop) => prop !== "left" && prop !== "right",
})(
  ({
    theme: {
      palette: {
        primary: { main },
        background: { light },
      },
    },
    theme,
    right,
    left,
  }) => ({
    [theme.breakpoints.up("xs")]: {
      fontSize: 16,
      padding: 9,
      width: 41,
      height: 37,
      borderBottomRightRadius: left ? 20 : 0,
      borderBottomLeftRadius: right ? 20 : 0,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 36,
      padding: 32,
      width: 108,
      height: 96,
      borderBottomRightRadius: left ? 44 : 0,
      borderBottomLeftRadius: right ? 44 : 0,
    },
    position: "absolute",
    left: left ? 0 : "inherit",
    top: 0,
    right: right ? 0 : "inherit",
    fontSize: 36,
    textAlign: "center",
    background: main,
    borderBottomRightRadius: left ? "44px" : "",
    borderTopLeftRadius: left ? "inherit" : "",
    borderBottomLeftRadius: right ? "44px" : "",
    borderTopRightRadius: right ? "inherit" : "",
    display: "flex",
    padding: 32,
    justifyContent: "center",
    alignItems: "center",
    color: light,
  })
);

const StyledBox = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "left" && prop !== "right",
})(({ theme, left, right }) => ({
  [theme.breakpoints.up("xs")]: {
    paddingLeft: left ? 51 : 0,
    paddingRight: right ? 51 : 0,
    paddingBlock: "10px",
    borderRadius: "18px",
    height: "100%",
    marginBottom: "30px",
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: left ? 132 : 45,
    paddingRight: right ? 132 : 45,
    borderTopLeftRadius: left ? 44 : 0,
    borderBottomLeftRadius: left ? 44 : 0,
    borderTopRightRadius: right ? 44 : 0,
    borderBottomRightRadius: right ? 44 : 0,
    height: "215px",
  },
  background: "rgba(195, 107, 214, 0.1)",
  position: "relative",
  width: "100%",
  maxWidth: "525px",
}));

const HowWeWork = () => {
  const [activeItem, setActiveItem] = useState(0);
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <section id="howWeWorks">
      <div className="container">
        <Stack textAlign="center" marginTop={10}>
          <Typography
            fontFamily="Sofia"
            color="secondary"
            fontSize={{ xs: "14px", sm: "20px", lg: "24px" }}
          >
            3 Easy Steps to Start Your Product
          </Typography>
          <Typography
            fontWeight={700}
            textTransform="uppercase"
            fontSize={{ xs: "20px", sm: "28px", lg: "34px" }}
          >
            🛠️ How Do We Work at TECHRIZZ
          </Typography>
          <Typography
            fontWeight={700}
            fontSize={{ xs: "12px", sm: "16px", lg: "20px" }}
          >
            A Seamless Journey to Digital Success
          </Typography>
        </Stack>
        <Grid container rowSpacing={1.25} columnSpacing={1.25}>
          <Grid item lg={6} className="work-name-list">
            {workList.map(({ header, description }, index) => (
              <Stack
                direction="row"
                justifyContent="space-between"
                gap={2}
                onClick={() => setActiveItem(index)}
                sx={{ cursor: "pointer" }}
                padding={2}
                key={index}
              >
                <Typography
                  fontWeight={700}
                  sx={(theme) => ({
                    ...(activeItem === index
                      ? {
                          background: theme.palette.gradient.main,
                          color: "transparent",
                          backgroundClip: "text",
                        }
                      : {}),
                    fontSize: { xs: "40px", md: "54px", lg: "72px" },
                  })}
                >
                  {(index + 1).toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })}
                </Typography>
                <Stack>
                  <Typography
                    variant="h5"
                    fontWeight={700}
                    sx={(theme) => ({
                      ...(activeItem === index
                        ? {
                            background: theme.palette.gradient.main,
                            color: "transparent",
                            backgroundClip: "text",
                          }
                        : {}),
                      fontSize: { xs: "16px", sm: "20px", ld: "26px" },
                    })}
                  >
                    {header}
                  </Typography>
                  <Typography fontSize={{ xs: "12px", lg: "14px" }}>
                    {description}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Grid>
          <Grid
            item
            lg={6}
            alignItems="center"
            display="flex"
            justifyContent="center"
            marginInline={{ xs: "16px", sm: "auto" }}
          >
            <video
              height="auto"
              autoPlay
              loop
              style={{
                borderRadius: matches ? "44px" : "20px",
                width: "100%",
                maxWidth: "610px",
                background: "#000",
              }}
              playsInline
              src={workList[activeItem].videoLink}
              muted
            />
          </Grid>
        </Grid>
      </div>
      <Container
        sx={({
          palette: {
            background: { light, dark },
          },
        }) => ({
          background: light,
          color: dark,
          borderRadius: matches ? "80px" : "50px",
          p: { xs: "18px 27px", lg: "80px 224px" },
        })}
        maxWidth="xl"
      >
        <Typography
          textTransform="uppercase"
          fontWeight={700}
          textAlign="center"
          mb={{ xs: "14px", lg: "43px" }}
          fontSize={{ xs: "20px", lg: "34px" }}
        >
          The Digital Lifecycle: Describe - Develop - Publish
        </Typography>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          gap={0}
          paddingBottom={2}
        >
          <StyledBox item lg={6} left>
            <StyledIcon left>
              <ArticleIcon sx={{ fontSize: matches ? "45px" : "20px" }} />
            </StyledIcon>
            <Typography variant="h6" fontWeight={700}>
              Describe
            </Typography>
            <Typography>
              Articulate your vision, goals, and digital aspirations. In this
              phase, we work closely to understand the intricacies of your
              project, ensuring a solid foundation for development.
            </Typography>
          </StyledBox>
          <Grid item lg={6} marginInline={{ xs: "auto" }}>
            <img
              src={describeImage}
              alt="describe"
              style={{ width: "100%", height: "100%" }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          paddingBlock={2}
          alignItems="center"
          textAlign="right"
          flexDirection={{ xs: "column-reverse", lg: "row" }}
        >
          <Grid item lg={6} marginInline={{ xs: "auto" }}>
            <img
              src={generateImage}
              alt="generate"
              style={{ width: "100%", height: "100%" }}
            />
          </Grid>
          <StyledBox item lg={6} right>
            <StyledIcon right>
              <EditNoteIcon sx={{ fontSize: matches ? "45px" : "20px" }} />
            </StyledIcon>
            <Typography variant="h6" fontWeight={700}>
              Generate
            </Typography>
            <Typography>
              Watch your vision come to life as our expert team engages in the
              development phase. Meticulously crafting each aspect, we transform
              ideas into tangible, functional, and innovative digital solutions.
            </Typography>
          </StyledBox>
        </Grid>
        <Grid
          container
          paddingBlock={2}
          alignItems="center"
          justifyContent="center"
          gap={0}
        >
          <StyledBox item lg={6} left>
            <StyledIcon left>
              <RocketLaunchIcon sx={{ fontSize: matches ? "45px" : "20px" }} />
            </StyledIcon>
            <Typography variant="h6" fontWeight={700}>
              Publish
            </Typography>
            <Typography>
              The final step - bringing your digital masterpiece to the world.
              TECHRIZZ ensures a seamless deployment, marking the culmination of
              our collaborative journey. Your project is now ready to make a
              lasting impact. 🚀🌐
            </Typography>
          </StyledBox>
          <Grid item lg={6} marginInline={{ xs: "auto" }}>
            <img
              src={publishImage}
              alt="publish"
              style={{ width: "100%", height: "100%" }}
            />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default HowWeWork;
