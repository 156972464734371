import React from "react";

import {
  Box,
  Grid,
  // useMediaQuery,
  Typography,
  alpha,
} from "@mui/material";
import styled from "@emotion/styled";

import { StyledButton } from "assets/styledComponents/StyledButton";
import whatBuildIcon1 from "assets/icons/whatBuildIcon1.svg";
import whatBuildIcon2 from "assets/icons/whatBuildIcon2.svg";

const StyledGrid = styled(Box, {
  shouldForwardProp: (prop) => prop !== "item",
})(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.light}`,
  borderRadius: 48,
  padding: 32,
  borderTopLeftRadius: 0,
  background: theme.palette.background.transparent,
  marginBottom: 36,
  transition: "all 0.5s ease-in",
  "&:hover": {
    boxShadow: `6px 6px 21px 0px ${alpha(theme.palette.primary.main, 0.2)}`,
  },
  position: "relative",
}));

const StyledIcon = styled(Box)(({ theme }) => ({
  color: theme.palette.background.light,
  background: theme.palette.gradient.main,
  padding: 10,
  borderRadius: 10,
  width: 50,
  height: 50,
  fontWeight: "bold",
  position: "absolute",
  top: -25,
}));

const WhatBuild = () => {
  return (
    <section id="whatWeBuild" className="container">
      <Grid
        container
        columnSpacing={3}
        alignItems="center"
        padding={{ xs: 3, md: 0 }}
        paddingTop={{ md: "146px" }}
        paddingBottom={{ md: "114px" }}
      >
        <Grid
          item
          sm={12}
          md={6}
          textAlign={{ xs: "center", md: "start" }}
          mb={2}
        >
          <Typography
            fontWeight={700}
            textTransform="uppercase"
            fontSize={{ xs: "20px", sm: "28px", lg: "34px" }}
          >
            What can you Build <br />
            with Techrizz?
          </Typography>
          <Typography
            marginBlock={{ xs: 1, sm: 2, lg: 4 }}
            fontSize={{ xs: "14px", md: "20px" }}
          >
            Trust that our team will deliver exceptional results for your
            website or any technology project. Backed by a wealth of experience
            and advanced development skills, we ensure a job well done. 🛠️🔍
          </Typography>
          <StyledButton variant="a" href="#contactUs">
            Let’s Discuss Your Idea
          </StyledButton>
        </Grid>
        <Grid item container columnSpacing={5} sm={12} lg={6}>
          <Grid item sm={12} md={6}>
            <StyledGrid item sm={12} md={6}>
              <StyledIcon>
                <img src={whatBuildIcon1} alt="icon1" />
              </StyledIcon>
              <Typography variant="h6">Website & SEO</Typography>
              <Typography variant="subtitle2">
                Boost your online presence with TECHRIZZ's SEO expertise. We
                ensure high search engine rankings, driving organic traffic and
                enhancing visibility. 🚀🌐
              </Typography>
            </StyledGrid>
            <StyledGrid item sm={12} md={6}>
              <StyledIcon>
                <img src={whatBuildIcon2} alt="icon2" />
              </StyledIcon>
              <Typography variant="h6">Scalable Backend</Typography>
              <Typography variant="subtitle2">
                Empower your digital backbone with TECHRIZZ's robust backend
                development. We ensure seamless functionality, security, and
                scalability for your dynamic online presence. ⚙️🌐
              </Typography>
            </StyledGrid>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            sx={{
              marginTop: {
                sm: 0,
                md: 6,
              },
            }}
          >
            <StyledGrid item sm={12} md={6}>
              <StyledIcon>
                <img src={whatBuildIcon2} alt="icon3" />
              </StyledIcon>
              <Typography variant="h6">Mobile Application</Typography>
              <Typography variant="subtitle2">
                Bring ideas to life with TECHRIZZ's app expertise. We craft
                user-friendly, innovative mobile applications tailored to your
                needs. 📱✨
              </Typography>
            </StyledGrid>
            <StyledGrid item sm={12} md={6}>
              <StyledIcon>
                <img src={whatBuildIcon1} alt="icon4" />
              </StyledIcon>
              <Typography variant="h6">Marketing</Typography>
              <Typography variant="subtitle2">
                Revamp your digital strategy with TECHRIZZ's marketing prowess.
                From strategy to execution, we help you effectively reach your
                audience, driving engagement and growth. 🚀📈
              </Typography>
            </StyledGrid>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};

export default WhatBuild;
