export const questions = [
  {
    question: "What services does TECHRIZZ offer?",
    answer:
      "TECHRIZZ provides a range of digital solutions, including website development, mobile app solutions, backend development, SEO services, and marketing excellence.",
  },
  {
    question:
      "How does TECHRIZZ ensure transparency during project development?",
    answer:
      "Transparency is a priority at TECHRIZZ. We provide regular progress updates, ensuring clients stay informed and engaged throughout the development process.",
  },
  {
    question:
      "Can TECHRIZZ customize its services based on specific business needs?",
    answer:
      "Absolutely. TECHRIZZ understands the uniqueness of each business. Our services are tailored to meet specific needs, ensuring a personalized and effective digital solution.",
  },
  {
    question: "What industries has TECHRIZZ worked with?",
    answer:
      "TECHRIZZ has successfully collaborated with clients across various industries, including e-commerce, health tech, finance, and real estate.",
  },
  {
    question: "How can I get started with TECHRIZZ for my project?",
    answer:
      "Initiating a project with TECHRIZZ is simple. Contact us through our website, and our team will reach out to discuss your ideas and requirements.",
  },
  {
    question: "Does TECHRIZZ provide ongoing support after project completion?",
    answer:
      " Yes, TECHRIZZ offers post-project support to ensure the smooth functioning of your digital solution. Our team is available for any assistance you may need.",
  },
  {
    question: "Why should I choose TECHRIZZ for my digital project?",
    answer:
      " Choosing TECHRIZZ means opting for innovation, expertise, and a client-centric approach. We bring a wealth of experience, personalized solutions, and a commitment to your project's success. Your digital journey begins and thrives with TECHRIZZ. 🌐🚀",
  },
  {
    question: "Do you offer an API?",
    answer:
      " Yes, TECHRIZZ provides API solutions to enhance the integration and functionality of your digital projects. Our APIs are designed to optimize performance, improve user experiences, and ensure seamless connectivity. If you have specific API-related inquiries or requirements, feel free to reach out to our team for detailed information and assistance. 🌐🚀",
  },
  {
    question: "How does TECHRIZZ compare to other services?",
    answer:
      "TECHRIZZ stands out among other services due to our unwavering commitment to innovation, quality, and client satisfaction. Our team brings a wealth of experience, delivering tailored solutions that align with your unique business goals. We prioritize transparency, communication, and the successful execution of every project. Choose TECHRIZZ for a distinctive approach to digital excellence and a partner dedicated to your success. 🌟🚀",
  },
];
