import React from "react";
import { Box, Container, Stack, Typography, alpha } from "@mui/material";

import personImage from "assets/images/person1.png";
import UserIcon from "@mui/icons-material/AccountBox";

const About = () => {
  return (
    <section id="about">
      <Container sx={{ paddingBlock: { xs: 4, xl: 12 } }}>
        <Typography
          textAlign="center"
          textTransform="uppercase"
          fontWeight={700}
          fontSize={{ xs: "20px", sm: "28px", lg: "34px" }}
        >
          Start Developing 10x faster with our team
        </Typography>
        <Typography textAlign="center" fontSize={{ xs: 14, lg: 20 }}>
          Write blog posts, website copy, social media ads and more, in a
          fraction of the time and cost compared to traditional copywriting.
        </Typography>
        <Box
          sx={(theme) => ({
            marginBlock: { xs: "20px", sm: "30px" },
            textAlign: "center",
            border: `1px solid ${theme.palette.primary.light}`,
            borderRadius: "44px",
            padding: { xs: "12px", sm: "26px 80px" },
            background: theme.palette.background.transparent,
            transition: "all 0.5s ease-in",
            "&:hover": {
              boxShadow: `6px 6px 21px 0px ${alpha(
                theme.palette.primary.main,
                0.2
              )}`,
            },
          })}
        >
          <Stack
            direction="row"
            justifyContent="center"
            gap={{ xs: "10px", sm: "58px" }}
          >
            <Box sx={{ width: { xs: "176px", sm: "352px" } }}>
              <img src={personImage} alt="team-person" width="100%" />
            </Box>
            <Box sx={{ maxWidth: "420px", width: "100%" }}>
              <Typography
                sx={{
                  fontSize: { xs: "16px", sm: "24px", md: "34px" },
                  marginBottom: "26px",
                }}
                fontWeight={700}
              >
                “ You must have to <br /> go through the
                <br /> process... ”
              </Typography>
              <Typography
                marginBottom="64px"
                display={{ xs: "none", sm: "block" }}
              >
                because at TECHRIZZ, we believe in a meticulous and thorough
                approach to ensure that every solution we provide is tailored to
                meet your unique needs.
              </Typography>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={{ xs: "2px", sm: "16px" }}
              >
                <UserIcon
                  sx={({
                    palette: {
                      gradient: { main },
                    },
                  }) => ({
                    background: main,
                    fontSize: { xs: "22px", sm: "34px" },
                  })}
                />
                <Stack>
                  <Typography
                    fontWeight={600}
                    fontSize={{ xs: "16px", sm: "28px" }}
                    textAlign="left"
                  >
                    Arfaz Chhapawala
                  </Typography>
                  <Typography
                    textAlign="left"
                    fontSize={{ xs: "14px", sm: "20px" }}
                    fontWeight={500}
                  >
                    Founder & CEO
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <Typography
            marginTop="56px"
            fontSize="14px"
            display={{ xs: "block", sm: "none" }}
          >
            because at TECHRIZZ, we believe in a meticulous and thorough
            approach to ensure that every solution we provide is tailored to
            meet your unique needs.
          </Typography>
        </Box>
      </Container>
    </section>
  );
};

export default About;
