import React, { useEffect, useState } from "react";

import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  Icon,
  Card,
  useMediaQuery,
} from "@mui/material";
import styled from "@emotion/styled";

import logo1 from "assets/images/art_brush_color_creative_design_icon.svg";
import logo2 from "assets/images/bill_invoice_receipt_icon.svg";
import logo3 from "assets/images/book_open_reading_icon.svg";
import logo4 from "assets/images/briefcase_business_office_icon.svg";
import logo5 from "assets/images/bxs_coin_stack_icon.svg";
import logo6 from "assets/images/camera_digital_photo_photograph_photography_icon.svg";
import logo7 from "assets/images/casual_clothing_dress_elegant_fashion_icon.svg";
import logo8 from "assets/images/chair_furniture_sofa_living_icon.svg";
import logo9 from "assets/images/church_religion_building_icon.svg";
import logo10 from "assets/images/creation_movie_icon.svg";
import logo11 from "assets/images/dumbell_fitness_sports_icon.svg";
import logo12 from "assets/images/dumbell_fitness_sports_icon1.svg";

import blogImage1 from "assets/images/blogImage1.png";

import { getData } from "utils";
import moment from "moment";
import Carousel from "react-multi-carousel";

const AnimatedStack = styled(Stack)({
  animation: "image-list-animation 8s infinite ease-in",
  alignItems: "center",
});

const AnimatedReverseStack = styled(Stack)({
  animation: "image-list-reverse-animation 8s infinite ease-in",
  alignItems: "center",
});

const StyledIcon = styled(Icon)({
  display: "inline-flex",
  borderRadius: 12,
  fontSize: 50,
  margin: 20,
  border: "2px solid #fff",
  alignItems: "center",
  justifyContent: "center",
});

const ImageBox = styled(Box)(
  ({
    theme: {
      palette: {
        gradient: { main },
      },
    },
  }) => ({
    display: "block",
    textAlign: "center",
    margin: "0 auto",
    background: main,
    borderRadius: "24px",
    maxHeight: "299px",
    height: "100%",
    maxWidth: "350px",
    width: "100%",
  })
);

const StyledCard = styled(Card)({
  padding: 16,
  borderRadius: 24,
  "&:hover": {
    img: {
      transform: "rotate(-5.241deg)",
      transition: "all 0.5s ease-in-out",
    },
  },
});

const StyledImage = styled.img({
  background: "#fff",
  borderRadius: "6px",
});

const imageList1 = [
  { src: logo1, alt: "logo1" },
  { src: logo2, alt: "logo2" },
  { src: logo3, alt: "logo3" },
  { src: logo4, alt: "logo4" },
  { src: logo5, alt: "logo5" },
  { src: logo6, alt: "logo6" },
  { src: logo1, alt: "logo1" },
  { src: logo2, alt: "logo2" },
  { src: logo3, alt: "logo3" },
  { src: logo4, alt: "logo4" },
  { src: logo5, alt: "logo5" },
  { src: logo6, alt: "logo6" },
];
const imageList2 = [
  { src: logo7, alt: "logo7" },
  { src: logo8, alt: "logo8" },
  { src: logo9, alt: "logo9" },
  { src: logo10, alt: "logo10" },
  { src: logo11, alt: "logo11" },
  { src: logo12, alt: "logo12" },
  { src: logo7, alt: "logo7" },
  { src: logo8, alt: "logo8" },
  { src: logo9, alt: "logo9" },
  { src: logo10, alt: "logo10" },
  { src: logo11, alt: "logo11" },
  { src: logo12, alt: "logo12" },
];

const responsiveSlider = {
  desktop: {
    breakpoint: { max: 3000, min: 1000 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1000, min: 576 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
};

const Services = () => {
  const [blogs, setBlogs] = useState([]);
  const isBigScreen = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    (async function fetchBlogs() {
      setBlogs(await getData("getAllBlogs"));
    })();
  }, []);

  return (
    <section id="services" className="container">
      <Grid
        container
        sx={{
          display: "flex",
          paddingBlock: { xs: 4, lg: 16 },
          alignItems: "center",
        }}
        spacing={2}
      >
        <Grid item sm={12} md={6}>
          <Typography
            textTransform="uppercase"
            fontWeight={700}
            fontSize={{ xs: "20px", sm: "28px", lg: "34px" }}
            paddingRight={{ xs: 0, lg: 20 }}
            textAlign={{ xs: "center", md: "left" }}
          >
            What can TECHRIZZ DO for you?
          </Typography>
          <Typography
            fontSize={{ xs: "14px", sm: "16px", lg: "18px" }}
            color="#EDEDED"
            fontWeight={500}
            textAlign={{ xs: "center", md: "left" }}
          >
            TECHRIZZ is your key to digital success. From stunning websites,
            user-friendly apps, and robust backend solutions to specialized SEO
            and marketing prowess, we've got you covered. Let TECHRIZZ unlock
            your full digital potential. 🌐🚀
          </Typography>
          <Divider
            sx={{
              width: "264px",
              background: "#fff",
              height: "2px",
              marginBlock: 2,
              visibility: { xs: "hidden", md: "visible" },
            }}
          />
          <Typography
            variant="h5"
            sx={({
              palette: {
                gradient: { main2 },
              },
            }) => ({
              background: main2,
              color: "transparent",
              backgroundClip: "text",
              textTransform: "uppercase",
              fontWeight: 700,
              fontSize: { xs: "20px", sm: "24px", lg: "30px" },
              textAlign: { xs: "center", md: "left" },
            })}
          >
            Over 100 + Successful Projects
          </Typography>
          <Typography
            fontSize={{ xs: "14px", sm: "16px" }}
            color="#EDEDED"
            fontWeight={500}
            textAlign={{ xs: "center", md: "left" }}
          >
            TECHRIZZ takes pride in delivering success through 100+ completed
            projects. Each project is a testament to our commitment to
            excellence and client satisfaction. 🌟🚀
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} marginInline="auto">
          <Box
            sx={(theme) => ({
              height: 550,
              maxWidth: 550,
              width: "100%",
              borderRadius: 11,
              padding: 1,
              margin: "0 auto",
              background: theme.palette.gradient.main,
              overflow: "hidden",
            })}
          >
            <Grid
              container
              sx={{
                columnSpacing: { xs: 0, md: 2 },
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={2}>
                <AnimatedStack>
                  {imageList1.map(({ src, alt }, index) => (
                    <StyledIcon key={index}>
                      <StyledImage src={src} alt={alt} />
                    </StyledIcon>
                  ))}
                </AnimatedStack>
              </Grid>
              <Grid item xs={2}>
                <AnimatedReverseStack marginBottom={6}>
                  {imageList2.map(({ src, alt }, index) => (
                    <StyledIcon key={index}>
                      <StyledImage src={src} alt={alt} />
                    </StyledIcon>
                  ))}
                </AnimatedReverseStack>
              </Grid>
              <Grid item xs={2}>
                <AnimatedStack>
                  {imageList1.map(({ src, alt }, index) => (
                    <StyledIcon key={index}>
                      <StyledImage src={src} alt={alt} />
                    </StyledIcon>
                  ))}
                </AnimatedStack>
              </Grid>
              <Grid item xs={2}>
                <AnimatedReverseStack marginBottom={6}>
                  {imageList2.map(({ src, alt }, index) => (
                    <StyledIcon key={index}>
                      <StyledImage src={src} alt={alt} />
                    </StyledIcon>
                  ))}
                </AnimatedReverseStack>
              </Grid>
              {isBigScreen && (
                <>
                  <Grid item xs={2}>
                    <AnimatedStack>
                      {imageList1.map(({ src, alt }, index) => (
                        <StyledIcon key={index}>
                          <StyledImage src={src} alt={alt} />
                        </StyledIcon>
                      ))}
                    </AnimatedStack>
                  </Grid>
                  <Grid item xs={2}>
                    <AnimatedReverseStack marginBottom={6}>
                      {imageList2.map(({ src, alt }, index) => (
                        <StyledIcon key={index}>
                          <StyledImage src={src} alt={alt} />
                        </StyledIcon>
                      ))}
                    </AnimatedReverseStack>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Stack justifyContent="center" paddingBottom={{ xs: 2, md: 6, lg: 8 }}>
        <Typography
          textTransform="uppercase"
          fontWeight={700}
          textAlign="center"
          fontSize={{ xs: "20px", sm: "28px", lg: "34px" }}
        >
          Our Projects
        </Typography>
        <Typography
          textAlign="center"
          marginBlock={2}
          fontSize={{ xs: "14px", sm: "16px", lg: "20px" }}
          color="#EDEDED"
          fontWeight={500}
        >
          Lorem ipsum dolor sit amet consectetur. Viverra tincidunt malesuada
          <br />
          cursus quam posuere erat aliquam arcu. Sollicitudin erat enim
          vestibulum.
        </Typography>
        {blogs.length ? (
          <div className="slider">
            <Carousel
              responsive={responsiveSlider}
              showDots={true}
              infinite={true}
              arrows={false}
              renderDotsOutside
            >
              {blogs.map(
                (
                  { blog_image, title, subtitle, content, createdAt },
                  index
                ) => (
                  <Box margin={2} key={index}>
                    <StyledCard>
                      <ImageBox>
                        <img src={blog_image || blogImage1} alt={title} />
                      </ImageBox>
                      <Typography fontWeight={700} marginTop={3} fontSize={18}>
                        {title}
                      </Typography>
                      <Typography marginBlock={2} fontSize={14}>
                        <Typography variant="span">
                          {content.length > 100
                            ? `${content.slice(0, 135)}...`
                            : content}
                        </Typography>

                        {content.length > 100 && (
                          <Typography
                            variant="span"
                            pl="10px"
                            color={({
                              palette: {
                                primary: { main },
                              },
                            }) => main}
                          >
                            Read More
                          </Typography>
                        )}
                      </Typography>
                      <Typography variant="subtitle2">
                        {moment(createdAt).format("DD-MMM, YYYY")}
                      </Typography>
                    </StyledCard>
                  </Box>
                )
              )}
            </Carousel>
          </div>
        ) : (
          <Card
            sx={{
              paddingBlock: { xs: 8, md: 18 },
              textAlign: "center",
              margin: 2,
              outlineWidth: "12px",
            }}
            elevation={4}
          >
            Blogs not available right now
          </Card>
        )}
      </Stack>
    </section>
  );
};

export default Services;
