import Home from "pages/Home";
import { alpha, createTheme, ThemeProvider } from "@mui/material";

function App() {
  const primaryMain = "#c36bd6";
  const secondaryMain = "#583fbb";

  const theme = createTheme({
    palette: {
      primary: {
        main: primaryMain,
        light: alpha(primaryMain, 0.5),
        dark: alpha(primaryMain, 0.9),
        transparent: "#c36bd61a",
      },
      secondary: {
        main: secondaryMain,
        light: alpha(secondaryMain, 0.5),
        dark: alpha(secondaryMain, 0.9),
      },
      gradient: {
        main: `linear-gradient(100deg, ${primaryMain} -10.33%, ${secondaryMain} 120.46%)`,
        main2: `linear-gradient(207deg, ${secondaryMain} -4.84%, ${primaryMain} 103.51%)`,
        main3: `linear-gradient(199deg, rgba(88, 63, 187, 0.10) -17%, rgba(195, 107, 214, 0.10) 102.05%)`,
        main4: `linear-gradient(90deg, ${secondaryMain} 70.03%, rgba(195, 107, 214, 0) 122.53%)`,
      },
      background: {
        main: "#22252D",
        light: "#fff",
        dark: "#000",
        grey: "#706E80",
        lightPink: `rgba(195, 107, 214, 0.10)`,
        lightBlue: `rgba(88, 63, 187, 0.10)`,
        light1: "#F8F8F8",
        transparent: "#6F7E9033",
        textDark: "#6F7E90",
      },
      info: {
        main: "#898",
      },
    },
    typography: {
      fontFamily: [
        "Montserrat",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Home />
      </ThemeProvider>
    </div>
  );
}

export default App;
