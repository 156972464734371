import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import { questions } from "assets/data/questionList";

const FAQs = () => {
  const [expanded, setExpanded] = React.useState(0);

  return (
    <section id="FAQs">
      <Container
        maxWidth="xl"
        sx={({
          palette: {
            background: { light, dark },
          },
        }) => ({
          background: light,
          color: dark,
          borderRadius: 16,
          padding: { xs: 1, md: 4 },
        })}
      >
        <Typography
          variant="h5"
          textAlign="center"
          textTransform="uppercase"
          fontWeight={700}
          marginBlock={4}
        >
          Frequently Asked Questions
        </Typography>
        {questions.map(({ question, answer }, index) => (
          <Box
            sx={{
              maxWidth: "800px",
              width: "100%",
              margin: "10px auto",
            }}
            key={index}
          >
            <Accordion
              sx={({
                palette: {
                  gradient: { main2 },
                  background: { light, light1, dark },
                },
              }) => ({
                background: expanded === index ? main2 : light1,
                color: expanded === index ? light : dark,
                borderRadius: "24px !important",
                boxShadow: "none",
              })}
              expanded={expanded === index}
              onClick={() =>
                setExpanded((prev) => (prev === index ? false : index))
              }
            >
              <AccordionSummary
                expandIcon={
                  expanded !== index ? (
                    <AddIcon />
                  ) : (
                    <RemoveIcon sx={{ color: "#fff" }} />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography fontSize={{ xs: "16px", md: "20px" }}>
                  {question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  color="#E1E1E1"
                  fontSize={{ xs: "12px", md: "16px" }}
                >
                  {answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Container>
    </section>
  );
};

export default FAQs;
