import { ReactComponent as ReviewIcon1 } from "assets/icons/reviewIcon1.svg";
import { ReactComponent as ReviewIcon2 } from "assets/icons/reviewIcon2.svg";
import { ReactComponent as ReviewIcon3 } from "assets/icons/reviewIcon3.svg";
import { ReactComponent as ReviewIcon4 } from "assets/icons/reviewIcon4.svg";

export const feedback = [
  {
    title: "E-commerce",
    icon: <ReviewIcon1 />,
    name: "Sarah Thompson",
    description:
      "TECHRIZZ exceeded our expectations! Their web development transformed our online store, enhancing user experience and boosting sales. Highly recommended!",
  },
  {
    title: "Health Tech",
    icon: <ReviewIcon2 />,
    name: "John Rodriguez",
    description:
      "Impressed with TECHRIZZ's app development! They brought our healthcare solution to life, ensuring a seamless and secure user experience. Fantastic work!",
  },
  {
    title: "Finance",
    icon: <ReviewIcon3 />,
    name: "Amanda Lee",
    description:
      "TECHRIZZ's marketing services elevated our digital presence. Their strategic approach and execution significantly increased our online visibility and client engagement.",
  },
  {
    title: "Real Estate",
    icon: <ReviewIcon4 />,
    name: "Michael Turner",
    description:
      "TECHRIZZ's expertise in backend development streamlined our operations. Their solutions ensured the seamless functioning and security of our real estate platform. Exceptional service",
  },
];
