import React from "react";
import { Divider, Grid, Link, Stack, Typography } from "@mui/material";

import logo from "assets/images/dark-logo.png";
import logoText from "assets/images/TechRizzDark.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import styled from "@emotion/styled";

const techServices = [
  { title: "AngularJS", link: "" },
  { title: "ReactJS / NextJS", link: "" },
  { title: "NodeJS", link: "" },
  { title: "Python", link: "" },
  { title: "Flutter", link: "" },
  { title: "DevOps", link: "" },
  { title: "WordPress", link: "" },
  { title: "AI/ML", link: "" },
  { title: "Digital Marketing", link: "" },
  { title: "Big Data", link: "" },
  { title: "MongoDB", link: "" },
  { title: "PWA", link: "" },
];

const aboutAndContactList = [
  { title: "Contact Us", link: "#contactUs" },
  { title: "Blog", link: "#services" },
  { title: "Privacy Policy", link: "" },
  { title: "Terms & Conditions", link: "" },
  { title: "FAQs", link: "#FAQs" },
  { title: "@TechRizz", link: "/" },
  { title: "Affiliate Program", link: "" },
];

const StyledFooterLink = styled(Link)(
  ({
    theme: {
      palette: {
        primary: { main },
        background: { dark },
      },
    },
  }) => ({
    color: dark,
    textDecoration: "none",

    "&:hover": {
      color: main,
      fontWeight: "bold",
    },
  })
);

const Footer = () => {
  return (
    <>
      <Stack
        sx={({
          palette: {
            background: { light, dark },
          },
        }) => ({
          background: light,
          color: dark,
          borderTopLeftRadius: { xs: 50, lg: 80 },
          borderTopRightRadius: { xs: 50, lg: 80 },
          padding: { xs: "32px 30px 0px", lg: "77px 100px 0px" },
          paddingTop: { xs: 4, sm: 6, lg: 10 },
          width: "100%",
          paddingBottom: 2,
        })}
      >
        <Grid container paddingBottom={{ xs: "41px", lg: "65px" }}>
          <Grid item sm={12} md={6} lg={4}>
            <Stack direction="row" alignItems="center" gap={1}>
              <img src={logo} alt="logo" width="40px" />
              <img src={logoText} alt="logoText" width={150} />
            </Stack>
            <Typography fontWeight={700} marginBlock={2} fontSize={24}>
              Contact Information :
            </Typography>
            <Typography
              paddingInlineEnd={{ xs: 0, lg: 24 }}
              color={({
                palette: {
                  background: { grey },
                },
              }) => grey}
            >
              TECHRIZZ International Ltd. 123 Tech Street, Cityville, Country
              Email: info@techrizz.com Phone: +1 (123) 456-7890
            </Typography>
            <Stack direction="row" alignItems="center">
              <FacebookIcon fontSize="large" sx={{ color: "#1877F2" }} />
              <InstagramIcon fontSize="large" sx={{ color: "#D90000" }} />
              <LinkedInIcon fontSize="large" sx={{ color: "#0A66C2" }} />
            </Stack>
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <Typography
              sx={(theme) => ({
                background: theme.palette.gradient.main,
                backgroundClip: "text",
                color: "transparent",
                fontSize: "18px",
                paddingBottom: "18px",
                [theme.breakpoints.between("xs", "sm")]: {
                  paddingTop: "30px",
                },
              })}
              fontWeight={700}
            >
              Tech Services
            </Typography>
            <Grid container spacing={1}>
              {techServices.map(({ title: technology, link }, index) => (
                <Grid item xs={6} key={index}>
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    component="li"
                    sx={{ listStyleType: "disc" }}
                  >
                    <StyledFooterLink href={!!link ? link : undefined}>
                      {technology}
                    </StyledFooterLink>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <Typography
              sx={(theme) => ({
                background: theme.palette.gradient.main,
                backgroundClip: "text",
                color: "transparent",
                fontSize: "18px",
                paddingBottom: "18px",
                [theme.breakpoints.between("xs", "sm")]: {
                  paddingTop: "30px",
                },
              })}
              fontWeight={700}
            >
              ABOUT & CONTACT
            </Typography>
            <Grid container spacing={1}>
              {aboutAndContactList.map(({ title, link }, index) => (
                <Grid item xs={6} key={index}>
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    component="li"
                    sx={{ listStyleType: "disc" }}
                  >
                    <StyledFooterLink href={!!link ? link : undefined}>
                      {title}
                    </StyledFooterLink>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Stack>
      <Stack
        sx={({
          palette: {
            background: { light, dark },
          },
        }) => ({
          background: light,
          color: dark,
          paddingBottom: "10px",
        })}
      >
        <Divider
          sx={({
            palette: {
              background: { grey },
            },
          }) => ({ background: grey, width: "100%" })}
        />
        <Typography textAlign="center" marginTop={1}>
          Copyright © {new Date().getFullYear()} TECHRIZZ | All Rights Reserved.
        </Typography>
      </Stack>
    </>
  );
};

export default Footer;
